@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'raber-13';
  src: url('../public/static/fonts/Rabar_013.ttf');
}

@font-face {
  font-family: 'raber-15';
  src: url('../public/static/fonts/Rabar_015.ttf');
}

@font-face {
  font-family: 'raber-39';
  src: url('../public/static/fonts/Rabar_039.ttf');
}
